@import './variable.scss';
@import './fonts.scss';
@import './mixin.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}
html {
  background-color: $bg-color;
  min-height: 100%;
  body {
    user-select: none;
    font-size: 12px;
    background-color: $bg-color;
    font-family: 'Avenir_Medium', Courier, monospace;
    // color: #000000bf;
    color: rgba($color: #000000, $alpha: 0.75);
    @include phone-s {    
      font-size: 12.5px; 
    }
    @include phone-m {    
      font-size: 13px; 
    }
    @media (min-width: 800px) {
      font-size: 18px;
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  width: 100%;
  > * {
    width: 100%;
  }
  main {
    flex-grow: 1;
    display: flex;
    > div {
      width: 100%;
    }
  }
  @media (min-width: 800px) {
    max-width: 600px;
    margin: 0 auto;
  }
}

#scrollnav {
  position: relative;
}

.container {
  padding: 0px 28px;
}
h1, .h1 {
  font-size: 2.7em;
  font-family: $font-sm;
  letter-spacing: 2px;
  line-height: 1.2;
  font-weight: normal;
}
h2, .h2 {
  font-size: 2.1em;
  font-family: $font-sm;
  letter-spacing: 2px;
  line-height: 1.2;
  font-weight: normal;
}

h3, .h3 {
  font-size: 1.53em;
  font-family: $font-sm;
  line-height: 1.5;
  margin-bottom: 18px;
  font-weight: normal;
  letter-spacing: 1px;
}

.pageUp {
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  background-color: #FFF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 2.8em;
  position: relative;
  cursor: pointer;
  &:after {
      transform: rotate(-90deg);
      font-size: 1em;
      position: relative;
      top: 0.1em;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url('/images/slider-right-arrow.svg') no-repeat center;
      background-size: contain;
  }
}