@import '@/styles/_variable.scss';

.slop {
    .bgSlop {
        position: relative;
        overflow: hidden;
        min-height: 40px;
        &:before {
            display: block;
            content: '';
            padding-bottom: 10%;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 150%;
            height: 100%;
            transform: matrix(1, -0.08, 0.05, 1, 0, 0);
            transform-origin: right top;
        }
        img {
            width: 6.6em;
            height: 6.3em;
            position: absolute;
        }
    }
    &.bg .bgSlop:after {
        background-color: $bg-color;
    }
    &.green .bgSlop:after {
        background-color: $color-green;
    }
}