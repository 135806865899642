@import '@/styles/_variable.scss';

.footer {
    min-height: 74px;
    position: relative;
    margin-top: -40px;
    .bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            width: 150%;
            height: 100%;
            transform: matrix(1, -0.08, 0.05, 1, 0, 0);
            transform-origin: right top;
            background-color: $bg-color;
        }
    }
    .cnt {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 40px 28px 14px 28px;
        gap: 20px;
        .cf {
            width: 40px;
            height: 25px;
            background: url('/images/cf-for-eng.png') no-repeat center left;
            background-size: contain;
        }
        .cfKorea {
            width: 40px;
            height: 25px;
            background: url('/images/cf-for-korea.png') no-repeat center left;
            background-size: contain;
        }
        .cfChina {
            width: 40px;
            height: 25px;
            background: url('/images/cf-for-china.png') no-repeat center left;
            background-size: contain;
        }
        .euEn {
            width: 116px;
            height: 24px;
            background: url('/images/theEUPOS-en.png') no-repeat center right;
            background-size: contain;
        }
        .euKorea {
            width: 116px;
            height: 24px;
            background: url('/images/theEUPOS-korea.png') no-repeat center right;
            background-size: contain;
        }
        .euTaiwan {
            width: 116px;
            height: 24px;
            background: url('/images/theEUPOS-taiwan.png') no-repeat center right;
            background-size: contain;
        }
        .euChina {
            width: 116px;
            height: 24px;
            background: url('/images/theEUPOS-china.png') no-repeat center right;
            background-size: contain;
        }
        .eie {
            width: 45px;
            position: relative;
            &:after {
                content: '';
                width: 100%;
                height: 78px;
                background: url('/images/enjoy-signature.png') no-repeat center;
                background-size: contain;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
        p {
            flex-grow: 1;
            text-align: center;
        }
    }
}