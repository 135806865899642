// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@400;900&display=swap');
@import url('https://fonts.font.im/css2?family=Noto+Serif+SC:wght@400;900&display=swap');


@font-face {
    font-family: 'Avenir_Medium';
    src: url('/fonts/Avenir-Medium.woff2') format('woff2'),
         url('/fonts/Avenir-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sugar_magicregular';
    src: url('/fonts/sugar_magic-webfont.woff2') format('woff2'),
         url('/fonts/sugar_magic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}