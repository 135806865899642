@mixin phone-s {
    @media (min-width: 390px) { @content; }
  }
  @mixin phone-m {
    @media (min-width: 420px) { @content; }
  }
  @mixin phone-l {
    @media (min-width: 500px) { @content; }
  }
  @mixin pad-s {
    @media (min-width: 600px) { @content; }
  }
  @mixin pad-m {
    @media (min-width: 760px) { @content; }
  }