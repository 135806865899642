@import '@/styles/_variable.scss';

.header {
    .stickybar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        padding: 20px;
        .logo {
            position: relative;
            z-index: 2;
        }
        > img {
            position: relative;
            z-index: 2;
        }
    }
    .langBtn, .navBtn {
        width: 42px;
        height: 42px;
        cursor: pointer;
        background-color: $bg-color;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .navBtn {
        row-gap: 4px;
        position: fixed;
        right: 20px;
        top: 20px;
        z-index: 7;
        i {
            width: 19px;
            min-height: 1px;
            background-color: $color-dark;
            &:nth-child(1) {
                animation: outT 0.8s backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                animation: outM 0.8s backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation: outBtm 0.8s backwards;
                animation-direction:reverse;
            }
        }
        &.open {
            i:nth-child(1) {
              animation: inT 0.8s forwards;
            }
            i:nth-child(2) {
              animation: inM 0.8s forwards;
            }
            i:nth-child(3) {
              animation: inBtm 0.8s forwards;
            }
          }
    }
    .langBtn {
        .lang {
            width: 18px;
            height: 18px;
            background: url('/images/lang.svg') no-repeat center;
            background-size: contain;
        }
        .link {
            width: 24px;
            height: 24px;
            background: url('/images/link.svg') no-repeat center;
            background-size: contain;
        }
    }
    .menu {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
        backdrop-filter: blur(4px);
        z-index: 6;
        display: flex;
        flex-direction: column;
        overflow: auto;
        transform: translateX(-110%);
        transition: transform 0.5s ease-in-out;
        &.show {
            transform: translateX(0%);
        }
        > * {
            width: 100%;
        }
        .stickynav {
            padding: 20px;
        }
        .logoNav {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
        }
        .menuCnt {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            background-color: $bg-color;
        }
        nav {
            padding: 10px 28px 40px 28px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 1.7em;
            justify-content: center;
            overflow: auto;
            a, .link {
                font-size: 2.3em;
                color: $color-green;
                border-bottom: 1px solid;
                text-decoration: none;
                font-family: $font-sm;
                padding-bottom: 0.15em;
                margin: 0;
                cursor: pointer;
                &:nth-child(2) {
                    color: $color-ping;
                }
                &:nth-child(3) {
                    color: $color-blue;
                }
                &:nth-child(4) {
                    color: $color-orange;
                }
            }
            &.langNav {
                a, .link {
                    color: $color-dark;
                    &:nth-child(2), &:nth-child(4) {
                        font-family: $font-cn;
                        font-weight: 900;
                    }
                }
            }
        }
    }
}


@keyframes inT{
    0%{transform: translateY(0px) rotate(0deg);}
    50%{transform: translateY(5px) rotate(0deg);}
    100%{transform: translateY(5px) rotate(135deg);}
}
@keyframes inBtm{
    0%{transform: translateY(0px) rotate(0deg);}
    50%{transform: translateY(-5px) rotate(0deg);}
    100%{transform: translateY(-5px) rotate(135deg);}
}
@keyframes inM{
    50%{transform:rotate(0deg);}
    100%{transform:rotate(45deg);}
}
@keyframes outT{
    0%{transform: translateY(0px) rotate(0deg);}
    50%{transform: translateY(5px) rotate(0deg);}
    100%{transform: translateY(5px) rotate(135deg);}
}
@keyframes outM{
    50%{transform:rotate(0deg);}
    100%{transform:rotate(45deg);}
}
@keyframes outBtm{
    0%{transform: translateY(0px) rotate(0deg);}
    50%{transform: translateY(-5px) rotate(0deg);}
    100%{transform: translateY(-5px) rotate(135deg);}
}